<template>
    <div class="c_content_container work">
        <!-- 상단배너 START -->
        <section class="c_section banner banner_work">
            <div class="c_inner">
                <div class="img_wrapper work">
                    <div class="icon_wrapper">
                        <!-- <img src="@/assets/images/banner/icon_work.png" alt="아이콘" class="icon" /> -->
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion>
                        <span class="textMotion_target">
                            <span class="textMotion_target"
                                ><span class="text_deco"></span>We Are
                                Owner!</span
                            > </span
                        ><br />
                        <span class="textMotion_target">
                            <span class="textMotion_target"
                                ><span class="text_deco"></span>위아오너는 맞춤
                                서비스를 통해</span
                            > </span
                        ><br />
                        <span class="textMotion_target">
                            <span class="textMotion_target"
                                ><span class="text_deco"></span>창업에 대한
                                <span class="text_deco">물음표</span>를
                                <span class="text_deco">마침표</span>로
                                바꿔드립니다.</span
                            > </span
                        ><br />
                        <span class="textMotion_target">
                            <span class="textMotion_target"
                                ><span class="text_deco"></span>창업의 시작,
                                창업의 모든 것 위아오너!</span
                            >
                        </span>
                    </h2>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <!-- 컨텐츠 START -->
        <section class="c_section work_content">
            <div class="c_inner">
                <span class="line"></span>
                <ul>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_01.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >창업 정보</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    창업정보 위아오너 홈에서는 창업정보를
                                    제공합니다. 슬라이드를 통해 다양한 정보를
                                    확인하세요! <br />
                                    창업연구소, 창업사례, 세무정보 등 사업준비,
                                    운영에 꼭 필요한 정보들이 업데이트 됩니다.
                                    지원사업은 지원분야, 지역, 소관부처, 업종의
                                    세부 카테고리별 사장님이 맞는 정보를
                                    필터링하여 한 눈에 볼 수 있습니다(기업마당).
                                    <br />
                                    상권분석은 위아오너 웹을 통해 만나보세요!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_02.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >AI 창업 견적 서비스</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    소상공인 연간 신규창업자 124만 명 그 중
                                    92.3%가 개인창업자이지만 창업시장은 다소
                                    프랜차이즈 위주로 되어 있어 정보획득에 많은
                                    어려움을 겪고 있습니다. 위아오너는
                                    개인창업자를 응원합니다. 창업 견적, 더 이상
                                    발품, 손품팔지 마세요. 위아오너에서 사장님의
                                    업종에 맞게 예산에 맞게 비대면 AI 견적을
                                    제공합니다. 비대면 AI견적을 지금 바로
                                    받아보세요! 사장님의 시간, 비용을
                                    아껴드립니다.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_03.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >인테리어 서비스</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    인테리어 비교 견적 단계부터 위아오너
                                    코디네이터가 함께합니다. 어려운 자재, 가격
                                    등 기준을 잡아드려요. 위아서비스에서
                                    ‘우리가게 창업비용’을 참고하세요! 같은 업종
                                    사장님들의 비용을 자세히 확인할 수 있어요~
                                    위아오너 인테리어는 평형별, 예산별에 맞게
                                    실제 사례를 보고 상담 받을 수 있습니다. 상가
                                    인테리어 전문업체가 하자·보수 걱정없이
                                    꼼꼼히 시공합니다. 초기비용이 부담스럽다면,
                                    무이자 카드할부,분할납부 서비스를
                                    이용해보세요!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_04.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >구매 서비스(창업마켓)</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    업태·업종별 세분화하여 견적받는 물품을 일괄
                                    또는 선별하여 구매할 수 있습니다. 위아오너
                                    코디네이터를 활용해보세요. 비교견적 서비스를
                                    제공하고 있어요. 초기비용이 부담스럽다면
                                    이용해보세요. 무이자할부, 36개월 분할납부가
                                    가능합니다.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_05.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >렌탈서비스</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    구매보다 렌탈로 이용하고 싶으신가요?
                                    결제(KIOSK, POS), 정수기, 주방기기
                                    보안(CCTV), 방역, 기타 등을 살펴보세요!
                                    위아오너에서는 일부 품목에 한해 ‘6개월
                                    렌탈비’를 지원하고 있습니다(30만원 이내)
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_06.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >사업운영</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    창업을 마친 사장님, 기창업자를 위해
                                    사업운영에 필요한 서비스를 제공합니다.
                                    세무(기장, 법인세, 부가세, 종합소득세 등)
                                    보험(화재보험, 자동차보험, 건강보험 등)
                                    마케팅(SNS, 검색포탈, 체험단, 홍보물 등)
                                    투자/자문/인증(벤처, ISO, 연구소, 교육 등)
                                    초기비용이 부담스럽다면 이용해보세요. 카드
                                    무이자할부가 가능합니다.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div v-imageMotion class="img_wrapper">
                            <div class="img_holder"></div>
                        </div>
                        <div class="text_wrapper">
                            <div class="icon_wrapper">
                                <img
                                    src="@/assets/images/work/icon_work_07.png"
                                    alt="아이콘"
                                />
                            </div>
                            <div v-fadein:reverse class="text">
                                <h2 v-textMotion>
                                    <span class="textMotion_target"
                                        ><span class="text_deco"
                                            >사업운영</span
                                        ></span
                                    >
                                </h2>
                                <p>
                                    창업을 마친 사장님, 기창업자를 위해
                                    사업운영에 필요한 서비스를 제공합니다.
                                    구인/구직(채용대행, 인력파견 등)
                                    개발(쇼핑몰, 솔루션/ERP, 앱, 유지보수 등)
                                    디자인(썸네일/상세페이지, 로고, 콘텐츠 등)
                                    초기비용이 부담스럽다면 이용해보세요. 카드
                                    무이자할부가 가능합니다.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- 컨텐츠 END -->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/work.css"></style>
